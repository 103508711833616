@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  min-height: 100vh;
  width: 100%;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  max-height: 120px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: hsl(var(--background));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  font-size: calc(14px + 1vmin);
  color: hsl(var(--foreground));
}

@media (min-width: 640px) {
  .App-header {
    padding: 2rem;
    font-size: calc(10px + 2vmin);
  }
}

.App-link {
  color: hsl(var(--primary));
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Mobile-first container styles */
.container {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

/* Responsive spacing utilities */
.responsive-padding {
  padding: 1rem;
}

@media (min-width: 640px) {
  .responsive-padding {
    padding: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .responsive-padding {
    padding: 2rem;
  }
}

/* Report-specific styles */
.report-table {
  @apply w-full border-collapse;
}

.report-table th {
  @apply bg-report-table-header text-report-header-foreground px-4 py-2 text-left font-semibold;
}

.report-table tr:nth-child(even) {
  @apply bg-report-table-row-even;
}

.report-table tr:nth-child(odd) {
  @apply bg-report-table-row-odd;
}

.report-table tr:hover {
  @apply bg-report-table-hover;
}

.report-table td {
  @apply px-4 py-2 border-t border-border;
}

.report-header {
  @apply bg-report-header text-report-header-foreground p-4 rounded-t-lg;
}

.report-success {
  @apply bg-report-success text-report-success-foreground;
}

.report-warning {
  @apply bg-report-warning text-report-warning-foreground;
}

.report-info {
  @apply bg-report-info text-report-info-foreground;
}

/* Chart colors utility classes */
.chart-color-1 { color: hsl(var(--chart-1)); }
.chart-color-2 { color: hsl(var(--chart-2)); }
.chart-color-3 { color: hsl(var(--chart-3)); }
.chart-color-4 { color: hsl(var(--chart-4)); }
.chart-color-5 { color: hsl(var(--chart-5)); }
.chart-color-6 { color: hsl(var(--chart-6)); }
.chart-color-7 { color: hsl(var(--chart-7)); }
.chart-color-8 { color: hsl(var(--chart-8)); }
.chart-color-9 { color: hsl(var(--chart-9)); }
.chart-color-10 { color: hsl(var(--chart-10)); }

.chart-bg-1 { background-color: hsl(var(--chart-1)); }
.chart-bg-2 { background-color: hsl(var(--chart-2)); }
.chart-bg-3 { background-color: hsl(var(--chart-3)); }
.chart-bg-4 { background-color: hsl(var(--chart-4)); }
.chart-bg-5 { background-color: hsl(var(--chart-5)); }
.chart-bg-6 { background-color: hsl(var(--chart-6)); }
.chart-bg-7 { background-color: hsl(var(--chart-7)); }
.chart-bg-8 { background-color: hsl(var(--chart-8)); }
.chart-bg-9 { background-color: hsl(var(--chart-9)); }
.chart-bg-10 { background-color: hsl(var(--chart-10)); }