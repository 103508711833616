@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
 
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 215 20.2% 65.1%;
 
    --radius: 0.5rem;

    /* Chart Colors */
    --chart-1: 221.2 83.2% 53.3%;
    --chart-2: 262.1 83.3% 57.8%;
    --chart-3: 291.5 95.5% 78.4%;
    --chart-4: 322.1 81.1% 43.4%;
    --chart-5: 155.7 74.6% 40.7%;
    --chart-6: 176.5 69.8% 41.2%;
    --chart-7: 197.1 71.4% 72.8%;
    --chart-8: 48.5 96.6% 88.8%;
    --chart-9: 32.1 94.6% 43.7%;
    --chart-10: 14.3 91.8% 56.1%;

    /* Report-specific colors */
    --report-success: 142.1 76.2% 36.3%;
    --report-success-foreground: 355.7 100% 97.3%;
    --report-warning: 38 92% 50%;
    --report-warning-foreground: 48 96% 89%;
    --report-info: 221 83% 53%;
    --report-info-foreground: 210 40% 98%;
    --report-header: 220 14% 96%;
    --report-header-foreground: 220 9% 46%;
    --report-table-header: 220 13% 91%;
    --report-table-row-even: 0 0% 100%;
    --report-table-row-odd: 220 14% 96%;
    --report-table-hover: 220 13% 91%;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
 
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
 
    --ring: 217.2 32.6% 17.5%;

    /* Dark mode chart colors */
    --chart-1: 217.2 91.2% 59.8%;
    --chart-2: 262.1 83.3% 57.8%;
    --chart-3: 291.5 95.5% 78.4%;
    --chart-4: 322.1 81.1% 43.4%;
    --chart-5: 155.7 74.6% 40.7%;
    --chart-6: 176.5 69.8% 41.2%;
    --chart-7: 197.1 71.4% 72.8%;
    --chart-8: 48.5 96.6% 88.8%;
    --chart-9: 32.1 94.6% 43.7%;
    --chart-10: 14.3 91.8% 56.1%;

    /* Dark mode report-specific colors */
    --report-success: 142.1 70.6% 45.3%;
    --report-success-foreground: 144.9 80.4% 10%;
    --report-warning: 48 96% 89%;
    --report-warning-foreground: 38 92% 50%;
    --report-info: 217.2 91.2% 59.8%;
    --report-info-foreground: 222.2 47.4% 11.2%;
    --report-header: 217.2 32.6% 17.5%;
    --report-header-foreground: 215 20.2% 65.1%;
    --report-table-header: 222.2 47.4% 11.2%;
    --report-table-row-even: 222.2 84% 4.9%;
    --report-table-row-odd: 223 47% 11%;
    --report-table-hover: 217.2 32.6% 17.5%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}